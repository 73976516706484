import { Box } from "@chakra-ui/react"
import BoxColumn from "@/components/atoms/BoxColumn/BoxColumn"
import remarkGfm from "remark-gfm"
import Markdown from "react-markdown"

import TaxiPrivacy from "./content/privacy-taxi.md?raw"
import RestoPrivacy from "./content/privacy-resto.md?raw"
import { dynamicValue } from "@/utils/verticale.ts"
import "./mardown.css"

const PrivacyPolicy = () => {
    return (
        <Box pb={{ base: "3rem", lg: "6rem" }} minH="70vh">
            <BoxColumn mt={{ base: "3rem", lg: "4rem" }}>
                <Box variant="base" color="#616161" textAlign="justify" fontSize={{ base: "1.4rem", lg: "1.6rem" }}>
                    <Markdown remarkPlugins={[remarkGfm]}>
                        {dynamicValue({ taxi: TaxiPrivacy, resto: RestoPrivacy })}
                    </Markdown>
                </Box>
            </BoxColumn>
        </Box>
    )
}

export default PrivacyPolicy
