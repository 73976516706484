import React, { Suspense } from "react";
import FallbackLoading from "../FallbackLoading";

const PaiementConfirmation = React.lazy(() => import("./PaiementConfirmation"));

const PaiementConfirmationPage = () => {
    return (
        <Suspense fallback={<FallbackLoading />}>
            <PaiementConfirmation />
        </Suspense>
    );
};

export default PaiementConfirmationPage;
