import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys);

const outline = definePartsStyle({
    control: {
        outline: "1px solid",
        outlineColor: "#767676",
        outlineOffset: "2px",
        mr: "1.2rem",
        borderRadius: "50%",
        boxShadow: "inset 0px 0px 0px 8px transparent",
        border: "none",
        _checked: {
            bg: "secondary",
            borderColor: "secondary",
            _before: {
                content: `""`,
                display: "inline-block",
                w: "100%",
                h: "100%",
                borderRadius: "50%",
                bg: "secondary",
            },
        },
    },
});
const outlineBordered = definePartsStyle({
    control: {
        border: "1px solid #767676",
        borderRadius: "50%",
        position: "relative",
        background: "#FFF",
        _checked: {
            _hover: {
                border: "1px solid secondary",
                background: "#FFF",
            },
            border: "1px solid secondary",
            background: "#FFF",
            _before: {
                content: `""`,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                w: "60%",
                h: "60%",
                borderRadius: "50%",
                bg: "secondary",
            },
        },
        _hover: {
            border: "1px solid secondary",
            background: "#FFF",
            _before: {
                content: `""`,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                w: "60%",
                h: "60%",
                borderRadius: "50%",
                bg: "secondary",
            },
        },
    },
});
const outlineBorderedGray = definePartsStyle({
    control: {
        border: "2px solid #C6C6C6",
        borderRadius: "50%",
        position: "relative",
        background: "#FFF",
        _checked: {
            _hover: {
                border: "2px solid #C6C6C6",
                background: "#FFF",
            },
            border: "2px solid #C6C6C6",
            background: "#FFF",
            _before: {
                content: `""`,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                w: "60%",
                h: "60%",
                borderRadius: "50%",
                bg: "secondary",
            },
        },
        _hover: {
            border: "1px solid secondary",
            background: "#FFF",
            _before: {
                content: `""`,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                w: "60%",
                h: "60%",
                borderRadius: "50%",
                bg: "secondary",
            },
        },
    },
});

const xl = defineStyle({
    w: "1.2rem",
    h: "1.2rem",
});
const xxl = defineStyle({
    w: "20px",
    h: "20px",
});

const sizes = {
    xl: definePartsStyle({ control: xl }),
    xxl: definePartsStyle({ control: xxl }),
};

export const radioTheme = defineMultiStyleConfig({
    variants: { outline, "outline-border": outlineBordered, "outline-border-gray": outlineBorderedGray },
    sizes,
});
