import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const primary = defineStyle({
    background: "secondary",
    color: "white",
    borderRadius: "0.2rem",
    _loading: {
        bg: "secondary !important",
    },
});

const primaryOutline = defineStyle({
    background: "#F8FAFF",
    borderRadius: "0.2rem",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "secondary",
    color: "secondary",
    _loading: {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "secondary",
        background: "#F8FAFF",
    },
    _hover: {
        background: "#DCE9FF",
    },
});

const grey = defineStyle({
    background: "#8A8A8A",
    color: "white",
    borderRadius: "0.2rem",
    _loading: {
        bg: "#8A8A8A !important",
    },
});

const variants = {
    primary,
    grey,
    "primary-outline": primaryOutline,
};

const base = defineStyle({
    fontSize: { base: "1.4rem", lg: "1.6rem" },
    fontWeight: 700,
    p: { base: "0.8rem 3.5rem" },
});

const lg = defineStyle({
    width: "100%",
    fontSize: "16px",
    fontWeight: 600,
    px: "16px",
    h: "44px",
    border: "1px solid",
    borderColor: "transparent",
});

const xl = defineStyle({
    width: "100%",
    fontSize: "2rem",
    fontWeight: 700,
    p: "0 1rem",
    h: { base: "5.2rem", lg: "6rem" },
});

const sizes = {
    base,
    lg,
    xl,
};

export const buttonTheme = defineStyleConfig({
    variants,
    sizes,
    baseStyle: {
        WebkitTapHighlightColor: "transparent",
    },
});
