import React, { Suspense } from "react";
import FallbackLoading from "../FallbackLoading";

const FAQ = React.lazy(() => import("./FAQ"));

const FAQPage = () => {
    return (
        <Suspense fallback={<FallbackLoading />}>
            <FAQ />
        </Suspense>
    );
};

export default FAQPage;
