import { Suspense, lazy } from "react";
import FallbackLoading from "../FallbackLoading";

const SubscriptionManagement = lazy(() => import("./SubscriptionManagement"));

const SubscriptionManagementPage = () => (
    <Suspense fallback={<FallbackLoading />}>
        <SubscriptionManagement />
    </Suspense>
);

export default SubscriptionManagementPage;
