import { inputAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle({
    field: {
        borderRadius: "0.3rem",
        backgroundColor: "white",
        color: "black",
    },
})

const blueStyle = definePartsStyle({
    field: {
        borderRadius: "0.2rem",
        backgroundColor: "white",
        color: "black",
        border: "0.2rem solid var(--chakra-colors-secondary)",
        _focus: {
            border: "0.2rem solid var(--chakra-colors-secondary)",
        },
    },
})

const variants = {
    base: baseStyle,
    blue: blueStyle,
}

const md = defineStyle({
    px: "1.4rem",
    h: { base: "3.2rem", lg: "6.2rem" },
    fontSize: { base: "1.4rem", lg: "1.6rem" },
})

const lg = defineStyle({
    px: "1.6rem",
    h: { base: "4.8rem", lg: "5.8rem" },
    fontSize: "1.6rem",
})

const xl = defineStyle({
    px: "1.6rem",
    h: { base: "5.2rem", lg: "6.2rem" },
    fontSize: "1.6rem",
})

const sizes = {
    md: definePartsStyle({ field: md, addon: md }),
    lg: definePartsStyle({ field: lg, addon: lg }),
    xl: definePartsStyle({ field: xl, addon: xl }),
}

export const inputTheme = defineMultiStyleConfig({ variants, sizes })
