import React, { Suspense } from "react";
import FallbackLoading from "../FallbackLoading";

const HowItsWork = React.lazy(() => import("./HowItsWork"));

const HowItsWorkPage = () => {
    return (
        <Suspense fallback={<FallbackLoading />}>
            <HowItsWork />
        </Suspense>
    );
};

export default HowItsWorkPage;
