import { lazy, Suspense } from "react"
import FallbackLoading from "@/pages/FallbackLoading.tsx"

const RestoResuts = lazy(() => import("./RestoResults.tsx"))
const RestoResutsPage = () => {
    return (
        <Suspense fallback={<FallbackLoading />}>
            <RestoResuts />
        </Suspense>
    )
}

export default RestoResutsPage
