import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const lg = defineStyle({
    color: "#000000",
    fontWeight: 700,
    fontSize:{
        base: "2.2rem",
        lg: "4rem",
    }
});

const variants = {
    lg,
};



export const headingTheme = defineStyleConfig({
    variants,
});
