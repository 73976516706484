import { useRedirectUrl } from "@/contexts/SessionContext.tsx"

export const useAssistanceTime = (): number => {
    const verticale = import.meta.env.VITE_VERTICALE
    const redirectUrl = useRedirectUrl()

    if (redirectUrl.includes("servi-life") || redirectUrl.includes("easy-rsv")) {
        return verticale === "taxi" ? 10 : 5
    }
    // CT
    else {
        return verticale === "taxi" ? 12 : 7
    }
}
