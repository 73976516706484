import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const blue = defineStyle({
    borderRadius: "0.2rem",
    backgroundColor: "white",
    color: "black",
    border:"0.2rem solid var(--chakra-colors-secondary)",
    _focus: {
        border:"0.2rem solid var(--chakra-colors-secondary)",
    }
});

const lg = defineStyle({
    fontSize: {base:"1.6rem"},
    p: "1.6rem",
    h:{base:"16rem"}
});

export const textareaTheme = defineStyleConfig({
    variants: { blue },
    sizes: { lg },
});
