import React, { Suspense } from "react";
import FallbackLoading from "../FallbackLoading";

const AboutUs = React.lazy(() => import("./AboutUs"));

const AboutUsPage = () => {
    return (
        <Suspense fallback={<FallbackLoading />}>
            <AboutUs />
        </Suspense>
    );
};

export default AboutUsPage;
