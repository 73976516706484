import { CSSReset, extendTheme } from "@chakra-ui/react"
import { colors } from "./colors"
import { textTheme } from "./components/Text"
import { headingTheme } from "./components/Heading"
import { inputTheme } from "./components/Input"
import { buttonTheme } from "./components/Button"
import { radioTheme } from "./components/Radio"
import { textareaTheme } from "./components/Textarea"
import { selectTheme } from "@/theme/components/Select.js"

const breakpoints = {
    sm: "320px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    "2xl": "1536px",
}

const fonts = {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
}

const styles = {
    global: {
        "html, body": {
            fontSize: "10px",
        },
        "input:focus": {
            border: "none",
            boxShadow: "none",
            outline: "none",
        },
        "input::placeholder": {
            color: "#767676",
        },
        "*": {
            boxSizing: "border-box",
        },
    },
}

const components = {
    Text: textTheme,
    Heading: headingTheme,
    Input: inputTheme,
    Button: buttonTheme,
    Radio: radioTheme,
    Textarea: textareaTheme,
    Select: selectTheme,
}

export const theme = extendTheme({
    styles,
    breakpoints,
    colors,
    fonts,

    CSSReset: {
        ...CSSReset.styles,
        "*": {
            boxSizing: "border-box",
        },
    },
    components,
})
