import { Box } from "@chakra-ui/react"
import BoxColumn from "@/components/atoms/BoxColumn/BoxColumn"
import remarkGfm from "remark-gfm"
import Markdown from "react-markdown"

import TaxiCgv from "./content/legals-taxi.md?raw"
import RestoCgv from "./content/legals-resto.md?raw"
import { dynamicValue } from "@/utils/verticale.ts"
import "./mardown.css"

const TermsOfUse = () => {
    return (
        <Box pb={{ base: "3rem", lg: "6rem" }} minH="70vh">
            <BoxColumn mt={{ base: "3rem", lg: "4rem" }}>
                <Box
                    // @ts-ignore
                    variant="base"
                    color="#616161"
                    textAlign="justify"
                    fontSize={{
                        base: "1.4rem",
                        lg: "1.6rem",
                    }}
                >
                    <Markdown remarkPlugins={[remarkGfm]}>{dynamicValue({ taxi: TaxiCgv, resto: RestoCgv })}</Markdown>
                </Box>
            </BoxColumn>
        </Box>
    )
}

export default TermsOfUse
