import { defineStyle, defineStyleConfig } from "@chakra-ui/react"

const extrasm = defineStyle({
    color: "#000000",
    fontSize: { base: "1rem", lg: "1.2rem" },
})

const sm = defineStyle({
    color: "#000000",
    fontSize: { base: "1.2rem", lg: "1.4rem" },
})

const base = defineStyle({
    color: "#000000",
    fontSize: { base: "1.4rem", lg: "1.6rem" },
})

const lg = defineStyle({
    color: "#000000",
    fontSize: { base: "1.6rem", lg: "1.8rem" },
})

const xl = defineStyle({
    color: "#000000",
    fontSize: { base: "2rem", lg: "3rem" },
    fontWeight: 600,
    lineHeight: { base: "2.4rem", lg: "3.6rem" },
})

const variants = {
    extrasm,
    base,
    sm,
    lg,
    xl,
}

export const textTheme = defineStyleConfig({
    baseStyle: {
        mb: 0,
    },
    variants,
})
