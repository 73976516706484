import { Box } from "@chakra-ui/react";

const BoxColumn = ({ children, ...rest }) => {
    return (
        <Box maxW={{ base: "50rem", lg: "150rem" }} px={{ base: "2rem", lg: "8rem" }} mx="auto" {...rest}>
            {children}
        </Box>
    );
};

export default BoxColumn;
