import React, { Suspense } from "react";
import FallbackLoading from "../FallbackLoading";

const JoinUs = React.lazy(() => import("./JoinUs"));

const JoinUsPage = () => {
    return (
        <Suspense fallback={<FallbackLoading />}>
            <JoinUs />
        </Suspense>
    );
};

export default JoinUsPage;
