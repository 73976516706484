import { Link, useLocation } from "react-router-dom"
import { Box, Flex, Heading, HStack, Image, Text } from "@chakra-ui/react"
import BoxColumn from "@/components/atoms/BoxColumn/BoxColumn"
import { CadenaIcon } from "@/theme/icons/Icons"
import paymentSecureIMG from "@/assets/images/cb.png"
import { dynamicValue } from "@/utils/verticale.ts"

const Footer = () => {
    const location = useLocation()
    const isHomepage = location.pathname === "/" || location.pathname === "/paiement-confirmation"
    const isTaxi = import.meta.env.VITE_VERTICALE === "taxi"

    return (
        <Box
            as="footer"
            bg="#FCFCFC"
            py={{ base: "3rem" }}
            pb={isHomepage ? "18rem" : "2rem"}
            zIndex={200}
            position="relative"
            mt={"auto"}
        >
            <BoxColumn>
                <Flex
                    direction={{ base: "column" }}
                    borderBottom="0.1rem solid #868686"
                    pb={{ base: "0.7rem", lg: "1.5rem" }}
                >
                    <Flex
                        w="100%"
                        borderBottom="0.1rem solid #868686"
                        pb={{ base: "0.6rem", lg: "1rem" }}
                        align="center"
                        justify="space-between"
                    >
                        <Heading
                            as="h3"
                            mt={{ base: "0.8rem", lg: "unset" }}
                            fontSize={{ base: "2rem" }}
                            fontWeight={700}
                        >
                            {dynamicValue({
                                taxi: "Taxi Reza",
                                resto: "Resto Reza",
                            })}
                        </Heading>
                        <HStack>
                            <CadenaIcon fill="black" w={{ base: "1.6rem" }} h={{ base: "1.6rem" }} />
                            <Image w={{ base: "10rem" }} src={paymentSecureIMG} loading={"lazy"} />
                        </HStack>
                    </Flex>
                    <Flex
                        direction={{ base: "column", lg: "row" }}
                        justify={{ lg: "space-between" }}
                        gap={{ lg: "8rem" }}
                    >
                        <Flex
                            mt={{ base: "1rem", lg: "1.3rem" }}
                            pb={{ base: "0.5rem", lg: "0" }}
                            borderBottom={{ base: "0.1rem solid #868686", lg: "none" }}
                            justify={{ base: "space-between", lg: "unset" }}
                            gap={{ lg: "8rem" }}
                        >
                            <Flex
                                direction="column"
                                gap={{ base: "0.4rem" }}
                                fontSize={{ base: "1.2rem", lg: "1.4rem" }}
                                pb={{ base: "0.6rem", lg: "1rem" }}
                            >
                                <Text decoration="underline" fontWeight="700">
                                    Légal
                                </Text>
                                {/* <Link to="/mentions-legales">Mentions légales</Link> */}
                                <Link to="/mentions-legales-et-cgv" target="_blank">
                                    Mentions légales et conditions générales de vente
                                </Link>
                                <Link to="/confidentialite" target="_blank">
                                    Charte de confidentialité
                                </Link>
                            </Flex>
                            {isTaxi && (
                                <Flex
                                    direction="column"
                                    gap={{ base: "0.4rem" }}
                                    fontSize={{ base: "1.2rem", lg: "1.4rem" }}
                                    pb={{ base: "0.6rem", lg: "1rem" }}
                                >
                                    <Text decoration="underline" fontWeight="700">
                                        Entreprise
                                    </Text>
                                    <Link to="/a-propos">A Propos</Link>
                                    <Link to="/carriere">Carrière</Link>
                                    <Link to="/engagements">Nos Engagements</Link>
                                    <Link to="/comment-ca-marche">Comment ça marche ?</Link>
                                    <Link to="/faq">FAQ</Link>
                                    {/*<Link to="/contact">Contact</Link>*/}
                                </Flex>
                            )}
                        </Flex>

                        <Box mt={{ base: "1rem", lg: "1.3rem" }} mr={{ lg: "5rem" }}>
                            <Text as="i" variant="sm" fontWeight="300" fontSize={{ base: "1.1rem", lg: "1.2rem" }}>
                                Horaires d’ouverture du service client : du lundi au vendredi de 9h à 18h
                            </Text>
                            {/*{isTaxi && (*/}
                            {/*    <Link to={"/contact"}>*/}
                            {/*        <Box*/}
                            {/*            mt={{ base: "1.2rem" }}*/}
                            {/*            bg="#2571FE"*/}
                            {/*            color="white"*/}
                            {/*            display={"flex"}*/}
                            {/*            justifyContent={"center"}*/}
                            {/*            alignItems={"center"}*/}
                            {/*            h={{ base: "4.1rem" }}*/}
                            {/*            w={{ base: "15.6rem" }}*/}
                            {/*            fontSize={{ base: "1.8rem" }}*/}
                            {/*            fontWeight={700}*/}
                            {/*        >*/}
                            {/*            Contact*/}
                            {/*        </Box>*/}
                            {/*    </Link>*/}
                            {/*)}*/}
                        </Box>
                    </Flex>
                </Flex>

                {isTaxi && (
                    <Text variant="extrasm" color="black" mt={{ base: "0.8rem", lg: "1.5rem" }}>
                        Taxi Reza n'est pas une centrale de taxi et n’agit qu’en{" "}
                        <Text as="strong">
                            qualité de comparateur et annuaire de taxi et vtc pour ses client. C’est le client qui
                            choisi le taxi souhaité et qui le contacte directement.
                        </Text>
                        <br />
                        La responsabilité contractuelle de Taxi Reza ne saurait être engagée pour une faute résultant de
                        la course ou de tout autre faute par le taxi ou le vtc.
                    </Text>
                )}
            </BoxColumn>
        </Box>
    )
}

export default Footer
