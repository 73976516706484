export const colors = {
    primary: "#0D2542",
    secondary: "#2571FE",
    green: {
        400: "#009721",
    },
    blue: {
        100:"#F3F7FF"
    }
};
