import React, { Suspense } from "react";
import FallbackLoading from "../FallbackLoading";

const Engagements = React.lazy(() => import("./Engagements"));

const EngagementsPage = () => {
    return (
        <Suspense fallback={<FallbackLoading />}>
            <Engagements />
        </Suspense>
    );
};

export default EngagementsPage;
