import { Flex, Spinner } from "@chakra-ui/react";
import { useEffect } from "react";

const FallbackLoading = () => {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "";
        };
    }, []);

    return (
        <Flex w="100vw" h="100vh" justify="center" align="center">
            <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                w={{ base: "50px", lg: "70px" }}
                h={{ base: "50px", lg: "70px" }}
            />
        </Flex>
    );
};

export default FallbackLoading;
