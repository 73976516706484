import ReactDOM from "react-dom/client"
import App from "./App.tsx"
import { BrowserRouter } from "react-router-dom"
import { initAmplitude } from "./services/amplitude"
import Hotjar from "@hotjar/browser"
import TagManager from "react-gtm-module"

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataLayer: Record<string, any>[]
    }
}

initAmplitude()

if (import.meta.env.VITE_VERTICALE === "taxi") {
    const tagManagerArgs = {
        gtmId: "GTM-NP6J27X",
    }
    TagManager.initialize(tagManagerArgs)
}

if (import.meta.env.VITE_VERTICALE === "resto") {
    const tagManagerArgs = {
        gtmId: "GTM-M5FP7ZB4",
    }
    TagManager.initialize(tagManagerArgs)
}

const getHotjarID = () => {
    if (import.meta.env.VITE_VERTICALE === "taxi") {
        return 4933053
    }

    if (import.meta.env.VITE_VERTICALE === "resto") {
        return 4933058
    }
}

const hotjarVersion = 6
const hotjarId = getHotjarID()
if (hotjarId) {
    Hotjar.init(hotjarId, hotjarVersion)
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
)
